<template>
  <div class="content">
    <Header :bg="bg"></Header>
    <div class="box"></div>
    <div class="song">
      <div class="mingzi">汉康之歌</div>
      <div class="ci">词：刘金彪</div>
      <div class="qu">曲：韩 毅</div>
      <div class="chang">演唱：胡 琳</div>
      <div class="ti">
        太阳神鸟展开了翅膀，<br />
        在无垠的河汉里翱翔。<br />
        都江堰凝固千年丰碑，<br />
        仿佛是五达为康的乐章。<br />
      </div>
      <div class="er">
        你用火眼金睛，洞察山川的异样；<br />
        你用大数据联结，中华城乡；<br />
        你用真情感知世界，焕发无穷能量；<br />
        汉康云是你的，你的智库。<br />
        啊，成都汉康，<br />
        噢，巴蜀汉康，<br />
        噢，华夏汉康，<br />
        噢，书写辉煌！<br />
      </div>
      <div class="san">
        不忘初心，暖心房。<br />
        我爱汉康，好自强……<br />
      </div>
      <div class="si">
        啊，<br />
        幸福汉康，我们的家，<br />
        无极之路，闪金光……<br />
      </div>
      <audio
        class="audio"
        src="@/assets/video/hksong.mp3"
        controls
        muted
      ></audio>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../../components/Header.vue";
import Footer from "../../../components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      bg: "black",
    };
  },
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.content {
  .box {
    width: 100%;
    height: 64px;
  }

  .song {
    width: 1920px;
    height: 864px;
    background: url("../../../assets/imgs/guanyu/song.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    .mingzi {
      font-size: 36px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: normal;
      line-height: 24px;
      color: #333333;
    }
    .ci {
      margin-top: 57px;
      font-size: 14px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: normal;
      line-height: 24px;
      color: #888888;
    }
    .qu {
      font-size: 14px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: normal;
      line-height: 24px;
      color: #888888;
    }
    .chang {
      font-size: 14px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: normal;
      line-height: 24px;
      color: #888888;
    }
    .ti {
      font-size: 16px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: normal;
      line-height: 22px;
      color: #333333;

      text-align: center;
      margin-top: 20px;
    }
    .er {
      font-size: 16px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: normal;
      line-height: 22px;
      color: #333333;

      text-align: center;
      margin-top: 14px;
    }
    .san {
      font-size: 16px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: normal;
      line-height: 22px;
      color: #333333;

      text-align: center;
      margin-top: 14px;
    }
    .si {
      font-size: 16px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: normal;
      line-height: 22px;
      color: #333333;

      text-align: center;
      margin-top: 14px;
    }
    .audio {
      margin-top: 70px;
      width: 560px;
      height: 50px;
      //   background-color: #fff;
    }
  }
}
</style>